import { html } from 'lit-element/lit-element';

import { constants, formatPhoneNoAnchor } from '../../../lib/js/util';

export function renderContact(contact) {
    return html`
    <div>
        ${contact.first_name}
        ${contact.last_name}
    </div>
    ${contact.cell ? html`
        <div>cell ${formatPhoneNoAnchor(contact.cell)}</div>
    `:""}
    ${contact.office ? html`
        <div>office ${formatPhoneNoAnchor(contact.office)}</div>
    `:""}
    ${contact.home ? html`
        <div>home ${formatPhoneNoAnchor(contact.home)}</div>
    `:""}
    <div class="break-word">${contact.email}</div>
    `;
}

// Renders contact information from both a contact and a customer, interleaved.
export function renderContactAndCustomer(contact, customer) {
    return html`
    <div>${contact.first_name} ${contact.last_name}</div>
    ${customer.type !== constants.residentialType ? html`
        <div>${customer.display_name}</div>
    `:''}
    <div class="break-word">${contact.email}</div>
    <div class="nowrap">
        ${contact.cell ? html`
            <div>cell ${formatPhoneNoAnchor(contact.cell)}</div>
        `:""}
        ${contact.office ? html`
            <div>office ${formatPhoneNoAnchor(contact.office)}</div>
        `:""}
        ${contact.home ? html`
            <div>home ${formatPhoneNoAnchor(contact.home)}</div>
        `:""}
        ${customer.type !== constants.residentialType ? html`
            ${customer.company_phone ? html`
                <div>phone ${formatPhoneNoAnchor(customer.company_phone)}</div>
            `:''}
            ${customer.company_fax ? html`
                <div>fax ${formatPhoneNoAnchor(customer.company_fax)}</div>
            `:''}
        `:''}
    </div>
    `;
}

export function renderAddressFull(a) {
    return html`
    <div>${a.name}</div>
    <div>${a.line1}</div>
    <div>${a.line2}</div>
    <div>${a.city}${a.city?', ':''}${a.state} ${a.postal}</div>
    <div class="muted">
        ${a.community ? a.community + (a.gate_code ? ',' : '') : ''}
        ${a.gate_code ? `Gate code: ${a.gate_code}` : ''}
    </div>
    <div class="muted">${a.label ? `(${a.label})` : ''}</div>
    `;
}

export function renderAddress(a) {
    return html`
    <div>${a.name}</div>
    <div>${a.line1}</div>
    <div>${a.line2}</div>
    <div>${a.city}${a.city?', ':''}${a.state} ${a.postal}</div>
    <div class="muted">${a.community}</div>
    `;
}

export function renderServiceAddress(p, full) {
    return html`
    ${p.delivery_method === constants.pickupDelivery ?
        "For customer pick-up"
    : p.delivery_method === constants.otherDelivery ?
        ""
    : html`
        <strong>${p.delivery_method} Address</strong>
    `}
    ${Object.values(p.service_address).some(v => v) ?
        full ? renderAddressFull(p.service_address) : renderAddress(p.service_address)
    :''}
    `;

}

// Join all the fields on one line.
export function joinFieldGroup(fg) {
    const totalStr = [];
    for (let i = 0; i < fg.fields.length; i++) {
        const field = fg.fields[i];
        let fieldStr = `${field.label}: ${field.value}`;
        if (i < fg.fields.length - 1) fieldStr += ", ";
        if (field.type == "Paragraph") {
            if (i > 0) fieldStr = "\n" + fieldStr;
            if (i < fg.fields.length - 1) fieldStr += "\n";
        }
        totalStr.push(fieldStr);
    }
    return totalStr.join('');
}

// Takes an item and return only its non-empty field groups.
export function trimEmptyMeta(item) {
    // Trims empty fields from an individual field group.
    const trimEmptyFG = item => ({...item, fields: item.fields.filter(f => f.value)});
    return item.meta.map(trimEmptyFG).filter(fg => fg.fields.length > 0);
}

export const iconMap = {
    [constants.salesAppointment]: html`<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z"/></svg>`,
    [constants.installationAppointment]: html`<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><g/><g><path d="M21.9,8.89l-1.05-4.37c-0.22-0.9-1-1.52-1.91-1.52H5.05C4.15,3,3.36,3.63,3.15,4.52L2.1,8.89 c-0.24,1.02-0.02,2.06,0.62,2.88C2.8,11.88,2.91,11.96,3,12.06V19c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-6.94 c0.09-0.09,0.2-0.18,0.28-0.28C21.92,10.96,22.15,9.91,21.9,8.89z M18.91,4.99l1.05,4.37c0.1,0.42,0.01,0.84-0.25,1.17 C19.57,10.71,19.27,11,18.77,11c-0.61,0-1.14-0.49-1.21-1.14L16.98,5L18.91,4.99z M13,5h1.96l0.54,4.52 c0.05,0.39-0.07,0.78-0.33,1.07C14.95,10.85,14.63,11,14.22,11C13.55,11,13,10.41,13,9.69V5z M8.49,9.52L9.04,5H11v4.69 C11,10.41,10.45,11,9.71,11c-0.34,0-0.65-0.15-0.89-0.41C8.57,10.3,8.45,9.91,8.49,9.52z M4.04,9.36L5.05,5h1.97L6.44,9.86 C6.36,10.51,5.84,11,5.23,11c-0.49,0-0.8-0.29-0.93-0.47C4.03,10.21,3.94,9.78,4.04,9.36z M5,19v-6.03C5.08,12.98,5.15,13,5.23,13 c0.87,0,1.66-0.36,2.24-0.95c0.6,0.6,1.4,0.95,2.31,0.95c0.87,0,1.65-0.36,2.23-0.93c0.59,0.57,1.39,0.93,2.29,0.93 c0.84,0,1.64-0.35,2.24-0.95c0.58,0.59,1.37,0.95,2.24,0.95c0.08,0,0.15-0.02,0.23-0.03V19H5z"/></g></g></svg>`,
    [constants.serviceCallAppointment]: html`<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path clip-rule="evenodd" d="M0 0h24v24H0z" fill="none"/><path d="M22.7 19l-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z"/></svg>`,
}
